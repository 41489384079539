// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'
import "./style.css"
// import moment from 'moment'

const usersColumns: ReadonlyArray<Column<User>> = [
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='From' className='w-200'  />,
    id: 'from',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} id="from" />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='For' className='w-200'  />,
    id: 'for',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} id="for" />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Reservation Number' className='w-200' />,
    id:'reservation_code',
    accessor: 'reservation_code'
  },

  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Reviews' className='w-220'  />,
    id:'review',
    accessor: 'comment'
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Ratings' className='w-180' />
    ),
    id: 'rating',
    Cell: ({...props}) => <UserTwoStepsCell rating={props.data[props.row.index]?.ratings} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Created At' className='w-180' />
    ),
    accessor: 'createdAt',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Updated At' className='w-180' />
    ),
    accessor: 'updatedAt',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='' className='text-end' className='w-110' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index]} />,
  },
]

export {usersColumns}
