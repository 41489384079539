import { useState, useEffect } from "react";
import axios from "axios";
import {
  KTCard,
  KTIcon,
  useDebounce,
  KTCardBody,
} from "../../../_metronic/helpers";
import Header from "./components/Header";
import { UserEditModal } from "./user-edit-modal/UserEditModal";
import { UserEditModal as PendingUserModal } from "./PendingUserModal/UserEditModal";
import { UserEditModal as EditListingModal } from "./SingleListing/user-edit-modal/UserEditModal";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import clsx from "clsx";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Link } from "react-router-dom";
import moment from "moment";
import { useAuth } from "../auth";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import {getListingPrices, generateURL, ReplaceAndCapitalize, isSearchAndFilterApplied} from "../../../_metronic/helpers/utils"

const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const Index = () => {
  const [searchParams] = useSearchParams();
  const { pathname, search: urlParams } = useLocation()
  const {currentUser, listingPage, setListingPage, haveAccess} = useAuth();
  const [searchType, setSearchType] = useState('search')
  const [search, setSearch] = useState(undefined);
  const [filter, setFilter] = useState({
    state: "",
    isZero:false
  });
  const [selectedListingId, setSelectedListingId] = useState(null);
  const [listings, setListings] = useState([]);
  const [total, setTotal] = useState(null);
  const [notifications, setNotifications] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(18);
  const [openPendingUserModal, setOpenPendingUserModal] = useState(false);
  const [openEditListingModal, setOpenEditListingModal] = useState(false);
  const [listingDetails, setListingDetails] = useState({});
  const [amenities, setAmenities] = useState([]);
  // const [isZero, setIsZero] = useState(false)
  const searchParamSearchType = searchParams.get("search_type");
  const searchParamSearch = searchParams.get("search");
  const searchParamStatus = searchParams.get("status");
  const searchParamAmenities = searchParams.get("amenities");
  const searchParamIsZero = searchParams.get("isZero");
  const searchParamPage = searchParams.get("page")
  const searchParamPageSize = searchParams.get("page_size")
  const navigate = useNavigate();
  const [lastLocation, setLastLocation] = useState(null);

  // const { placePredictions, isPlacePredictionsLoading } =
  //   useGoogle({
  //     apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  //   });


  useEffect(() => {
    if(searchParamSearchType || searchParamSearch || searchParamStatus || searchParamAmenities || searchParamPage || searchParamPageSize){
      setSearchType(searchParamSearchType ? searchParamSearchType : 'search')
      setSearch(searchParamSearch ? searchParamSearch : '')
      setFilter({status: searchParamStatus ? searchParamStatus : '', isZero: searchParamIsZero ? searchParamIsZero : false});
      setAmenities(searchParamAmenities ? searchParamAmenities?.split(",")?.map((item) => parseInt(item)) : []);
      setPage(searchParamPage ? parseInt(searchParamPage) : 1)
      setListingPage(searchParamPage ? parseInt(searchParamPage) : 1)
      setPageSize(searchParamPageSize ? parseInt(searchParamPageSize) : 18)
    }else{
      setSearchType('search')
      setSearch("")
      setFilter({status: '', isZero: false});
      setAmenities([]);
      setPage(1)
      setListingPage(1)
      setPageSize(18)
    }
  }, [searchParams]);


  const openAddUserModal = (id) => {
    setSelectedListingId(id);
  };

  const updatePage = (type) => {
    if (type === "dec" && listingPage > 1 && listingPage !== 1) {
      setPage(page - 1);
      setListingPage(listingPage - 1);
    } else if (type === "inc" && listingPage >= 1) {
      if(listingPage !== Math.ceil(total / pageSize)){
        setPage(page + 1);
        setListingPage(listingPage + 1);
      }
    }else{
      setPage(type);
      setListingPage(type)
    }
  };

  useEffect(() => {
    if(currentUser.role == 1 && currentUser.listing_id){
      navigate(`/listings/${currentUser.listing_id}`);
    }
  },[currentUser])



  const fetchListing = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/listing?page=${listingPage}&page_size=${pageSize}&sort=4&${searchType}=${(searchType === 'state' || searchType === 'city') ? debouncedSearchTerm.replace(/(\S) (\S)/g, '$1-$2') : encodeURIComponent(debouncedSearchTerm?.trim())}&status=${filter.status}&zero_seats=${filter.isZero}&amenities=${amenities.map((item) => item).join(",")}`
      )
      .then((res) => {
        setListings(res.data.data);
        setTotal(res.data.total);
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        console.error(err);
      });
  };


  const debouncedSearchTerm = useDebounce(search, 700);

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && search !== undefined && searchType) {
      const newUrl = generateURL('/listings', {
        page: total < pageSize ? 1 : page,
        page_size: pageSize,
        search_type: searchType,
        search: debouncedSearchTerm,
        status: filter?.status,
        amenities: amenities?.map((item) => item).join(","),
        isZero: filter?.isZero
      })

      if (lastLocation && lastLocation !== newUrl) {
        navigate(newUrl)
      }
      setLastLocation(newUrl)
      fetchListing();
    }
  }, [debouncedSearchTerm, filter, page, amenities, pageSize, total]);


useEffect(() => {
  if(total && (Math.ceil(total / pageSize) < page)){
    console.log("terst")
    setPage(1);
    setListingPage(1)
  }
},[total, page])


  // useEffect(() => {
  //   if(search && debouncedSearchTerm){
  //     setPage(1);
  //     setListingPage(1)
  //   }
  //   if(pageSize !== 18){
  //    setPage(1);
  //    setListingPage(1)
  //   }
  // }, [debouncedSearchTerm, pageSize]);
  
  

  const duration = (duration) => {
    switch (duration) {
      case 1:
        return "hour";
        break;
      case 2:
        return "day";
        break;
      case 3:
        return "week";
        break;
      case 4:
        return "month";
        break;
    }
  };

  const PendingApproval = (id, type) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/listing/${id}?is_approved=${type}`
      )
      .then((res) => {
        toast(`${type === false ? "Approval Rejected" : "Approval Accepted"}`, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        fetchListing();
      })
      .catch((err) => {
        toast("Error Pending Approval", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
  };


const openListingModal = (listing) => {
  axios.get(`${process.env.REACT_APP_API_URL}/listing/${listing}`).then((res) => {
    const listing = res.data;
    setListingDetails(listing);
    setOpenEditListingModal(true);
  });
}


  return (
    (haveAccess(currentUser, "listing_list")) ?
    <Elements stripe={stripePromise}>
      {notifications && (
        <div
          className="alert alert-primary d-flex align-items-center p-5 mb-10 "
          style={{
            position: "fixed",
            top: 15,
            left: "55%",
            zIndex: 9999,
            transform: "translateX(-55%)",
            width: "max-content",
          }}
        >
          <span className="svg-icon svg-icon-2hx svg-icon-primary me-3">
            ...
          </span>

          <div className="d-flex flex-column">
            {/* <h5 className="mb-1">This is an alert</h5> */}
            <span>Error</span>
          </div>
        </div>
      )}
      <KTCard>
        <Header search={search} setSearch={setSearch} filter={filter} setFilter={setFilter} setAmenities={setAmenities} amenities={amenities} searchType={searchType} setSearchType={setSearchType} />
        <KTCardBody className="py-0">
        <h6
        style={{
        color: "#7e8299",
        textAlign: "left"
        }}>
        {isSearchAndFilterApplied(search, filter, total, amenities)}
        </h6>
        </KTCardBody>
        <KTCardBody className="py-10">
          <div className="row g-10">
            {listings && listings.length > 0 ? (
              listings
                // .filter((item) => item?.prices?.length > 0)
                .map((listing) => (
                  <div
                    className="col-12 col-md-6 col-lg-4"
                    style={{ position: "relative" }}
                  >
                    {(listing.status === 4 ||
                      listing.status === 5 ||
                      listing.status === 0 ||
                      listing.status === 2 ||
                      listing.status === 3 ||
                      listing.status === 1 || listing.status === -1) && (
                      <span
                        className={`badge badge-${
                          listing.status === 5
                            ? "danger"
                            : listing.status === 4
                            ? "success"
                            : listing.status === 2 || listing.status === 3
                            ? "info"                         
                            : listing.status === 0 && "dark"
                        }`}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: "16px",
                          width: "91%",
                          margin: "0 auto",
                          zIndex: 2,
                          borderRadius: 0,
                          height: "30px",
                          fontSize: "14px",
                          fontWeight: 700,
                          textAlign: "center",
                          background: listing.status === 1 ? "#ffa459" : listing.status === -1 && '#ed2626',
                          color: (listing.status === 1 || listing.status === -1) && "#fff",
                        }}
                      >
                        {listing.status === 4
                          ? "Pending For Approval"
                          : listing.status === 5
                          ? "Approval Rejected"
                          : listing.status === 3
                          ? "On Hold By Admin"
                          : listing.status === 2
                          ? "On Hold By User"
                          : listing.status === 1
                          ? "Active"
                          : listing.status === -1 ? "Deleted"
                          : listing.status === 0 && "Draft Listing"}
                      </span>
                    )}
                    <div className="card card-custom gutter-10">
                      {listing.images && listing.images.length ? (
                        <div style={{ position: "relative", width:'100%' }}>
                        <img
                          src={
                            listing?.avatar
                              ? `${process.env.REACT_APP_IMAGE_URL}300:200:${listing?.avatar.slice(1)}`
                              : listing?.images &&
                                listing?.images?.length > 0 &&
                                process.env.REACT_APP_IMAGE_URL + '300:200:' + 
                                  listing?.images[0]?.file.slice(1)
                          }
                          // src={
                          //   listing?.images[0]?.file?.includes("imported")
                          //     ? process.env.REACT_APP_AWS_URL +
                          //       listing?.images[0]?.file
                          //     : process.env.REACT_APP_AWS_URL +
                          //       listing?.images[0]?.file
                          // }
                          style={{ cursor: "pointer" }}
                          height={250}
                          width={'100%'}
                          alt=""
                          onClick={() => {
                            (haveAccess(currentUser, "listing_details")) &&
                            navigate(`/listings/${listing.listing_id}`);
                          }}
                        />
                      {listing.status == 3 && listing.disable_reason && <div
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "rgba(0,0,0,0.75)",
                            color: "#fff",
                            fontSize: "16px",
                            cursor: "pointer",
                            position: "absolute",
                            top: 0,
                            left: 0,   
                            padding: "10px",                         
                          }}
                          onClick={() => {
                            (haveAccess(currentUser, "listing_details")) &&
                            navigate(`/listings/${listing.listing_id}`);
                          }}
                        >
                       <p> Disabled Reason: {listing.disable_reason}
                       </p>
                        </div>
}
                        </div>
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            height: "250px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // border: "1px solid #ffa459",
                            background: "gray",
                            color: "#fff",
                            fontSize: "16px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            (haveAccess(currentUser, "listing_details")) &&
                            navigate(`/listings/${listing.listing_id}`);
                          }}
                        >
                          No Preview Image Available Yet
                        </div>
                      )}
                      <div className="row pt-4" style={{ height: "auto" }}>
                        <div
                          style={{
                            minHeight:'auto',
                            maxHeight: "auto",
                            display: "grid",
                            alignItems: "center",
                          }}
                        >
                          <h4
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              (haveAccess(currentUser, "listing_details")) &&
                              navigate(`/listings/${listing.listing_id}`);
                            }}
                          >
                            {listing?.title && listing?.title?.length >= 60 ? `${listing.title.substring(0, 60)}...` : listing.title }
                          </h4>
                      {currentUser.role !== 6 &&    <div className="col-12">
                            <h6>
                              Created At: {moment(listing?.createdAt).format("MMMM DD YYYY, dddd")}
                            </h6>
                          </div>
}
{currentUser.role !== 6 &&   <div className="col-12">
                            <h6>
                              Hosted By: <Link
                               to={`/users/${listing.owner_uid}`}
                                style={{
                                color:'#2ecc71',                   
                                fontWeight: "600",
                               }}> 
                               {listing.owner_first_name}{" "}
                              {listing.owner_last_name}{" "}
                              </Link>
                            </h6>
                          </div>
}
                        </div>
                        {currentUser.role !== 6 && <div className="col-12">
                          {listing.seats ? (
                            <h6 style={{ color: +listing?.available_seats <= 0 || listing?.is_zero ? "#ed2626"  :"#ffa459" }}>
                              {+listing?.available_seats <= 0 || listing?.is_zero ? 0 : listing?.available_seats} Spaces Available
                            </h6>
                          ) : (
                            <h6>Space Availabilty not added yet</h6>
                          )}
                        </div>
}
                        {listing.prices && listing.prices.length > 0 ? (
                            <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "10px",
                            }}
                          >
                            {getListingPrices(listing, listing.prices.find((price) => price.vehicle_type > 0)?.vehicle_type ? listing.vehicle_type_allowed !== 0 ? listing.vehicle_type_allowed : listing.prices.filter((price) => price.plan_duration !== 5).find((price) => price.vehicle_type !== 3) ? listing.prices.filter((price) => price.plan_duration !== 5)[0]?.vehicle_type : 3 : 0)
                              .sort((a, b) => a.plan_duration - b.plan_duration)
                              .map(
                                (price) =>
                                  price.price > 0 &&  price.plan_duration !== 5 && (
                                    <div
                                      style={{
                                        fontSize: "16px",
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "#ffa459",
                                          fontWeight: "700",
                                          fontSize: "18px",
                                        }}
                                      >
                                        ${(price.price / 100).toFixed(2)}
                                      </span>{" "}
                                      <span style={{ fontSize: "12px" }}>
                                        per {duration(price?.plan_duration)}
                                      </span>
                                    </div>
                                  )
                              )}
                          </div>
                        
                        ) : (
                        currentUser.role !== 6 &&      <div
                            style={{
                              color: "#4a4a4a",
                              fontWeight: "700",
                              fontSize: "18px",
                              height: "45px",
                            }}
                          >
                            No Prices Added Yet
                          </div>
                        )}
                        {((haveAccess(currentUser, "listing_request_response")) && listing.status === 4) ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "3px",
                              alignItems: "center",
                            }}
                          >
                            {/* <button
                              type="button"
                              className="btn mt-4 w-100"
                              onClick={() =>
                                PendingApproval(listing.listing_id, false)
                              }
                              style={{
                                background: "#ed2626",
                                color: "#fff",
                                height: "46px",
                                fontWeight: "700",
                              }}
                            >
                              Decline
                            </button> */}
                            <button
                              type="button"
                              className="btn mt-4 w-100"
                              onClick={() => {
                                setSelectedListingId(listing);
                                setOpenPendingUserModal(true);
                                // PendingApproval(listing.listing_id, true)
                              }}
                              style={{
                                background: "#2ecc71",
                                color: "#fff",
                                height: "46px",
                                fontWeight: "700",
                              }}
                            >
                              Pending For Approval
                            </button>
                          </div>
                        ) : ((haveAccess(currentUser, "booking_create")) && listing.status === 1) ? (
                          <button
                            type="button"
                            className="btn mt-4"
                            onClick={() => openAddUserModal(listing)}
                            style={{
                              background: "#ffa459",
                              color: "#fff",
                              height: "46px",
                              fontWeight: "700",
                            }}
                            disabled={listing.status === 1 ? false : true}
                          >
                            Book For User
                          </button>
                        ) : (
                        currentUser.role !== 6  &&  <button
                            type="button"
                            className="btn mt-4"
                            onClick={() =>
                              (haveAccess(currentUser, "listing_details")) &&
                              navigate(`/listings/${listing.listing_id}`)
                            }
                            style={{
                              background: "#4a4a4a",
                              color: "#fff",
                              height: "46px",
                              fontWeight: "700",
                            }}
                          >
                            Check Listing
                          </button>
                        //   : <button
                        //   type="button"
                        //   className="btn mt-4"
                        //   onClick={() =>
                        //    openListingModal(listing.listing_id)
                        //     // navigate(`/listings/${listing.listing_id}`)
                        //   }
                        //   style={{
                        //     background: "#4a4a4a",
                        //     color: "#fff",
                        //     height: "46px",
                        //     fontWeight: "700",
                        //   }}
                        // >
                        //   Edit Seats
                        // </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <div className="d-flex text-center w-100 align-content-center justify-content-center">
                No matching records found
              </div>
            )}
          </div>

          <div className="row mt-10">
            <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
              <div style={{display: "flex", gap: "10px", alignItems:'center'}}>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select Status'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
              onChange={(e) => setPageSize(e.target.value)}
              value={pageSize}
            >
               <option value='18'>18</option>
               <option value='36'>36</option>
               <option value='54'>54</option>
               <option value='72'>72</option>     
            </select>
              </div>
            </div>
            <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-lg-end">
              <div id="kt_table_users_paginate">
                <ul className="pagination">            
                  {listingPage > 1 &&   <li className={clsx("page-item", "previous")}>
                      <a
                        className={clsx("page-link", "next", "page-text")}
                        onClick={() => updatePage("dec")}
                        style={{ cursor: "pointer" }}
                        disabled={listingPage === 1}
                      >
                        Previous Page
                      </a>
                    </li>  
} 
                    <div style={{display:'flex', gap:'5px', fontWeight:700, fontSize:'14px'}}>     
                    <span style={{color:'#ffa459'}}>{listingPage}</span>/ {Math.ceil(total / pageSize) || 1}
                     {/* {new Array(Math.ceil(total / 18)).fill(null).map((el, i) => (
                                <button
                                    key={i}
                                    style={{ cursor: "pointer", background:'transparent', color:i + 1 === page ? '#ffa459' : '#000', border:'none', fontWeight:700  }}
                                    // className={i + 1 === page ? "page-link" : ''}
                                    onClick={() => setPage(i + 1)}
                                >
                                    {i + 1}
                                </button>
                            ))} */}
                            </div>
                  <li className={clsx("page-item", "previous", listingPage === Math.ceil(total / pageSize) && "disabled" )}>
                    <a
                      className={clsx("page-link")}
                      onClick={() => updatePage("inc")}
                      style={{cursor:listingPage !== Math.ceil(total / pageSize) ? "pointer" : "default", background:listingPage !== Math.ceil(total / pageSize) ? "transparent" : "#e6e6e6", color: listingPage !== Math.ceil(total / pageSize) ? '#5e6278': "#b2b2b2", marginLeft: listingPage === Math.ceil(total / pageSize) && '10px'}}
                    >
                      Next Page
                    </a>
                  </li>
                  {Math.ceil(total / pageSize) > 5 && <div style={{ display: "flex", gap: "10px", alignItems: "center", marginLeft:'10px' }}>
                      <h6 style={{marginBottom:'0'}}>Go To Page: </h6>
                        <select
                          style={{ width: "max-content" }}
                          className="form-select form-select-solid fw-bolder"
                          data-kt-select2="true"
                          data-placeholder="Select Status"
                          data-allow-clear="true"
                          data-kt-user-table-filter="two-step"
                          data-hide-search="true"
                          onChange={(e) => updatePage(+e.target.value)}
                          value={listingPage}
                        >
                        {
                       [...Array(Math.ceil(total / pageSize))].map((_, i) => {
                        return (
                            <option value={i + 1}>{i + 1}</option>
                        )
                      }      
                      )}
                        </select>
                      </div>
                      }
                </ul>
              </div>
              </div>
          </div>
        </KTCardBody>
      </KTCard>
      {selectedListingId && !openPendingUserModal && (
        <UserEditModal
          setSelectedListingId={setSelectedListingId}
          selectedListingId={selectedListingId}
        />
      )}
      {selectedListingId && openPendingUserModal && (
        <PendingUserModal
          selectedListingId={selectedListingId.listing_id}
          setSelectedListingId={setSelectedListingId}
          listing={selectedListingId}
          getListing={fetchListing}
          openAdditionalUserModal={openPendingUserModal}
          setOpenAdditionalUserModal={setOpenPendingUserModal}
        />
      )}
         {openEditListingModal && (
          <EditListingModal
            selectedListingId={listingDetails?.listing_id}
            setSelectedListingId={setListingDetails}
            openEditListing={openEditListingModal}
            setOpenEditListing={setOpenEditListingModal}
            openCommissionModal={false}
            setOpenCommissionModal={() => {}}
            listing={listingDetails}
            getListing={() => {}}
          />
        )}
    </Elements>
    : 
    <div className='d-flex text-center w-100 align-content-center justify-content-center' style={{ height: '70vh', alignItems: 'center', fontSize: '42px', color: '#ffa049', background: 'rgba(0,0,0,0.1)' }}>
    You do not have permission to view
  </div>
    
  );
};

export default Index;
